.custom-navbar {
  background-image: linear-gradient(to right, #FFC0CB, #FF69B4);
}

.custom-navbar .navbar-brand {
  color: white !important;
  font-family: 'Quicksand', sans-serif;
}

.custom-navbar .navbar-toggler {
    color:white;
    border-color: white;
    background-color: transparent;
}

.custom-navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
