.page-loading-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* To ensure it stays behind other content */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.pink-button {
  background-color: #ffa9d5 !important;
  border-color: #ffa9d5 !important;
}

.pink-button:hover {
  background-color: #FF69B4 !important;
  border-color: #FF69B4 !important;
}

.aboutme-description {
  text-align: left;      /* Align the text to the left */
  font-size: 1.2em;      /* Increase the font size */
  width: 50%;            /* Set width similar to the <hr> tag */
  margin: 0 auto;        /* Center the paragraph with the given width */
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  .aboutme-description {
    width: 90%; /* or even 100%, based on your design preference */
  }
}

.custom-tooltip {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: var(--bs-tooltip-zindex, 1080);
    max-width: var(--bs-tooltip-max-width, 200px);
    padding: var(--bs-tooltip-padding-y, 0.25rem) var(--bs-tooltip-padding-x, 0.5rem);
    font-size: var(--bs-tooltip-font-size, 0.875rem);
    border-radius: var(--bs-tooltip-border-radius, 0.375rem);
    background-color: var(--bs-tooltip-bg, #000);
    color: var(--bs-tooltip-color, #fff);
    opacity: var(--bs-tooltip-opacity, 0.9);
    text-align: center;
    white-space: nowrap;
    font-family: var(--bs-font-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif);
}

.custom-tooltip:after {
    content: '';
    position: absolute;
    bottom: -0.4rem; /* Matching arrow height as Bootstrap */
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 0.4rem solid transparent; /* Half of Bootstrap's tooltip arrow width */
    border-right: 0.4rem solid transparent; /* Half of Bootstrap's tooltip arrow width */
    border-top: 0.4rem solid var(--bs-tooltip-bg, #000);
}


.always-show-toggle {
    display: block !important;
}

/* style.css */
.nav-link-bold {
    font-family: 'Quicksand', sans-serif !important;
    font-weight: bold !important;
}

.circular-image {
  width: 150px;   /* or any size you want */
  height: 150px;  /* same as width */
  border-radius: 50%;  /* this gives the circular shape */
  object-fit: cover;  /* this prevents distortion */
  border: 6px solid pink;
}

.social-icon {
  margin-left: 10px;
  height: 21px;
  width: auto;
}

.social-icon-2 {
  margin-left: 10px;
  height: 25px;
  width: auto;
}

.gift-icon {
  height: 180px;
  width: auto;
}

.input-speaker-icon {
  height: 20px;
  width: auto;
}

.speaker-icon {
  height: 50px;
  width: auto;
}

.soundwaves-icon {
  height: 70px;
  width: auto;
}

.mobile-input {
  border-radius: 0 !important; /* this will remove the rounded edges */
  width: 100% !important; /* this will make it full width */
  box-sizing: border-box; /* this will make sure the width includes padding and border */
}

.password-input {
  margin-bottom: 16px;
}

.buy-me-coffee {
  background-color: #343a40;
  color: white;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  margin-left: 1rem;
}

.homepage-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
  text-align: center;
}

.name-box {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%);
  padding: 10px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  color: white;
  z-index: 2;
}

.showlove-box {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: none;
  padding: 3px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  color: white;
  z-index: 2;
}

.name-box i {
  margin-right: 10px;
}

.speaker-switch-button {
  background-color: black !important;

}

.speaker-switch-button:hover {
  background-color:	#88A3B7 !important;
}


.custom-input {
  width: 70% !important;
  height: 150% !important;
  border: solid !important; /* Add this line */
  border-width: 2px !important; /* Add this line */
}

.default-videoplaceholder-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Ensure the message is beneath the video */
  text-align: center;
  color: white; /* Adjust the color as needed */
}

.default-videoplaceholder-message .dot1 {
  animation: dot1 1.5s infinite;
}

.default-videoplaceholder-message .dot2 {
  animation: dot2 1.5s infinite;
}

.default-videoplaceholder-message .dot3 {
  animation: dot3 1.5s infinite;
}

.video-container {
  position: relative;
  height: calc(100vh - 56px - 48px - 2rem);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center center; /* Ensures the image is centered */
}

@media (max-width: 768px) {
  .input-group .btn {
    width: 100%;
  }
}

/* Add a media query for screens smaller than 600px,
typically targeting mobile devices in portrait mode */
@media (max-width: 600px) {
  .video-container {
    /* We'll keep it simple and just set the height to 70vh for mobile.
       This should make the video take up most of the screen's height. */
    height: 65vh;
  }

  .input-container {
    /* We'll set the height of the input-container to take up the remaining space */
    height: 35vh;
    /* To make sure the input field and button fits within this space,
       you might need to adjust their sizes or the container's padding. */
  }

  .speaker-icon {
    height: 70px;
    width: auto;
  }

  .switch-chat-mode {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    font-size: 12px;
    padding-left: 10px;
  }


}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2; /* Ensure the video is above the error message */
}

#mouth-open {
  display: none;
}

.navbar-brand {
  font-size: 1.5rem !important;
}

.form-transparent {
  background-color: transparent;
  border: none;
}

.quicksand-bold {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}

.input-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.switch-chat-mode {
  position: absolute;
  bottom: 0;
  right: 40px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.thinking-text-style {
  margin-top: 20px;
  font-size: 20px;
  color: rgb(255, 133, 194);
  font-family: 'Quicksand';
  font-weight: bold;
}

/* Animation code */
@keyframes dot1 {
  0%, 30% {opacity: 0;}
  40%, 100% {opacity: 1;}
}

@keyframes dot2 {
  0%, 40% {opacity: 0;}
  50%, 100% {opacity: 1;}
}

@keyframes dot3 {
  0%, 50% {opacity: 0;}
  60%, 100% {opacity: 1;}
}

.thinking-text-style .dot1 {
  animation: dot1 1.5s infinite;
}

.thinking-text-style .dot2 {
  animation: dot2 1.5s infinite;
}

.thinking-text-style .dot3 {
  animation: dot3 1.5s infinite;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bottom-navbar-container .bottom-navbar-link,
.bottom-navbar-container .text-center.text-muted {
    font-size: 75%;
}

.bottom-navbar-container .bottom-navbar-link {
    margin: 0 5px;
    transition: color 0.3s;
}

.bottom-navbar-container .bottom-navbar-link:not(.active):hover {
    color: #b0b0b0;
}
