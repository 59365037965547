
.tokens-card {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    text-align: center;
}

.tokens-card h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.tokens-card .mb-4 {
    margin-bottom: 25px !important;
    color: #777;
}

.tokens-card button {
    font-size: 1rem;
}

h4 {
    margin-top: 40px;
    text-align: center;
    color: #555;
}

Table {
    margin-top: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

Table th, Table td {
    text-align: center;
}

Table tbody tr:hover {
    background-color: #f0f0f5;
}
